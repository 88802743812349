import {Component, Inject, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {environment} from '../environments/environment';
import {LOCATION} from './providers';
import {CoreState} from './core/store';
import {meENLang, meESLang, mcsENLang, mcsESLang, salusENLang, salusESLang, pharmpixENLang, pharmpixESLang, LanguageSymbol, TranslationService} from './core/i18n';

const APP_ID_KEY = environment.LOCAL_STORAGE_KEY.APP_ID;
const APP_CONFIG = environment.APP_CONFIG;

@Component({
  selector: 'body[root]',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCATION) private location: Location,
    private titleService: Title,
    private translationService: TranslationService,
    private coreState: CoreState,
  ) {}

  ngOnInit(): void {
    const faviconLinkEl = this.document.getElementById('favicon') as HTMLLinkElement;
    const themeLinkEl = this.document.getElementById('theme') as HTMLLinkElement;
    const hostname = this.location.hostname;

    switch(true) {
      case new RegExp(APP_CONFIG.ME.DOMAIN).test(hostname):
        this.translationService.loadTranslations(meENLang, meESLang);
        this.coreState.set('appId', APP_CONFIG.ME.ID, APP_ID_KEY);
        this.titleService.setTitle(APP_CONFIG.ME.TITLE);
        faviconLinkEl.href = APP_CONFIG.ME.FAVICON;
        themeLinkEl.href = APP_CONFIG.ME.THEME;
        break;

      case new RegExp(APP_CONFIG.MCS.DOMAIN).test(hostname):
        this.translationService.loadTranslations(mcsENLang, mcsESLang);
        this.coreState.set('appId', APP_CONFIG.MCS.ID, APP_ID_KEY);
        this.titleService.setTitle(APP_CONFIG.MCS.TITLE);
        faviconLinkEl.href = APP_CONFIG.MCS.FAVICON;
        themeLinkEl.href = APP_CONFIG.MCS.THEME;
        break;

      case new RegExp(APP_CONFIG.SALUS.DOMAIN).test(hostname):
        this.translationService.loadTranslations(salusENLang, salusESLang);
        this.coreState.set('appId', APP_CONFIG.SALUS.ID, APP_ID_KEY);
        this.titleService.setTitle(APP_CONFIG.SALUS.TITLE);
        faviconLinkEl.href = APP_CONFIG.SALUS.FAVICON;
        themeLinkEl.href = APP_CONFIG.SALUS.THEME;
        break;

      case new RegExp(APP_CONFIG.PHARMPIX.DOMAIN).test(hostname):
        this.translationService.setDefaultLanguage(LanguageSymbol.SPANISH);
        this.translationService.loadTranslations(pharmpixENLang, pharmpixESLang);
        this.coreState.set('appId', APP_CONFIG.PHARMPIX.ID, APP_ID_KEY);
        this.titleService.setTitle(APP_CONFIG.PHARMPIX.TITLE);
        faviconLinkEl.href = APP_CONFIG.PHARMPIX.FAVICON;
        themeLinkEl.href = APP_CONFIG.PHARMPIX.THEME;
        break;
    }

    this.translationService.setLanguage(this.translationService.getSelectedLanguage());
  }
}

import {LanguageSymbol} from '../../enums';
// Spain
export const locale = {
  lang: LanguageSymbol.SPANISH,
  data: {
    APP: {
      NAME: 'PORTAL',
      GENERAL: {
        LANGUAGE: 'Lenguaje',
        ENGLISH: 'English',
        SPANISH: 'Spanish',
        AND: 'y',
        EDIT: 'Editar',
        REMOVE: 'Remover',
        BACK: 'Atrás',
        OK_GOT_IT: '¡Ok, entendido!',
        CONTINUE: 'Continuar',
        IGNORE: 'Ignorar',
        SUBMIT: 'Enviar',
        FINISH: 'Terminar',
        CLOSE: 'Cerca',
        ADD: 'Añadir',
        UPDATE: 'Actualizar',
        COMPLETE_SUBSCRIPTION: 'Suscripción completa',
      },
      SIGNUP: {
        FIELD: {
          ZIPCODE: 'Código Postal',
          MCS_CARDHOLDER: 'Número de suscriptor de MCS',
          REFERRAL_CODE: 'Código de referido',
          GIVEN_NAME: 'Nombre',
          SECOND_NAME: 'Segundo nombre',
          FAMILY_NAME: 'Apellido paterno',
          MOTHERS_MAIDEN_FAMILY_NAME: 'Apellido materno',
          DATE_OF_BIRTH: 'Fecha de nacimiento',
          GENDER: 'Género',
          CELL_NUMBER: 'Teléfono móvil',
          HOME_NUMBER: 'Teléfono del hogar',
          FAX_NUMBER: 'Teléfono de fax',
          EMAIL: 'Correo electrónico',
          USERNAME: 'Nuevo nombre de usuario',
          PASSWORD: 'Nueva contraseña',
          RE_PASSWORD: 'Vuelva a introducir la contraseña',
          DELIVERY_ZIPCODE: 'Código postal de lugar de entrega',
          HOME: 'Casa',
          OFFICE: 'Oficina',
          OTHER: 'Institución',
          URBANIZATION: 'Urbanización',
          INSTITUTION: 'Institución',
          ADDRESS_LINE1: 'Dirección línea 1',
          ADDRESS_LINE2: 'Dirección línea 2',
          CITY: 'Ciudad',
          STATE: 'Estado',
          ZIPCODE_4CODE: 'Código Postal + 4Code',
          DELIVERY_INSTRUCTIONS: 'Instrucciones de entrega',
          PRESS123_AT_FRONT_GATE: 'Presione \'123\' en la puerta principal.',
          PREFERRED_ADDRESS: 'Dirección preferida',
          TYPE_OF_ADDRESS: 'Tipo de dirección',
          CATEGORY: 'Categoría',
          IMAGE: 'Imagen',
          SKU: 'SKU',
          NAME: 'Nombre',
          COUNT: 'Contar',
          PRICE: 'Precio',
          VALIDATION: {
            REQUIRED: '{{label}} debe ser completada',
            INVALID: '{{label}} no válida',
            NO_WHITESPACE: '{{label}} no válida: No se permiten espacios en blanco',
            MIN: '{{label}} debe ser mayor que {{min}}',
            EXISTS: '{{label}} ya está en uso',
            PATTERN: '{{label}} debe coincidir con el patrón',
            PW_MATCH: 'Las contraseñas no coinciden',
          },
        },
        PROGRAM_TYPE: {
          MCS_PROGRAMS: 'Programas MCS',
          SELECT_PROGRAM_YOU_WANT_TO_REGISTER_FOR: 'Seleccione el programa en el cual desea registrarse.',
          REGISTRATION_PROCESS_MAY_DIFFER_BASED_ON_YOUR_SELECTION: 'El proceso de registro pudiera diferir según su selección.',
          HOME_PROGRAM: 'MCS Care Program',
          REGISTER_FOR_THE_MCS_HOME_PROGRAM: 'Regístrese en el MCS Care Program',
          BY_MAKING_A_SELECTION_YOU_AGREE_WITH_OUR: 'Al hacer una selección, usted está de acuerdo con nuestro',
          TERMS_OF_SERVICES: 'Términos de Servicios',
          PRIVACY_POLICY: 'Política de Privacidad',
        },
        CHECK_ELIGIBILITY: {
          ELIGIBILITY_CHECK: 'Verificación de elegibilidad',
          CHECK_IF_THE_PATIENT_IS_ELIGIBLE_FOR_THIS_PROGRAM: 'Verifique si el paciente es elegible para este programa.',
          INVALID_DATA_PROVIDED: 'Los datos proporcionados no concuerdan con nuestros sistemas. Verifique el número de titular de su tarjeta y la fecha de nacimiento e intente nuevamente.',
          ALREADY_ENROLLED: 'Este tarjetahabiente ya ha sido registrado. Póngase en contacto con nuestro departamente de servicio al cliente para cualquier modificación.',
        },
        BASIC_INFO: {
          BASIC_INFORMATION: 'Información básica',
          ADD_YOUR_BASIC_INFORMATION: 'Agrega tu información básica.',
          ALREADY_EXIST_PART_1: 'Un contacto con este número telefonico existe. Por favor intente ',
          ALREADY_EXIST_PART_2: ' con su Medplus User Id o de entender que esto es un error, por favor comuniquese con nuestro departamento de servicio al cliente.',
          LOGIN: 'ingresar',
        },
        CREATE_ACCOUNT: {
          CREATE_ACCOUNT: 'Crear cuenta',
          ADD_YOUR_USERNAME_AND_PASSWORD: 'Agregue su usuario y contraseña.',
          GREAT_NEWS: '¡Una gran noticia! Usted es elegible para este programa, agregue su nombre de usuario y contraseña.',
          MIN_8CHARS: '8 caracteres o más',
          MIN_1UPPERCASE: 'Al menos una (1) letra mayúscula',
          MIN_1NUMBER: 'Al menos un (1) número',
          MIN_1SPECIAL: 'Al menos un (1) carácter especial',
        },
        DELIVERY_ADDRESS: {
          DELIVERY_ADDRESS: 'Dirección de entrega',
          ADD_YOUR_DELIVERY_ADDRESS: 'Añade tus direcciones de entrega.',
          YOUR_DELIVERY_ADDRESSES: 'Sus direcciones de entrega',
          ADD_NEW_ADDRESS: 'Añadir una nueva dirección',
          NO_ADDRESS_ADDED: '¡No se añadió ninguna dirección!',
          ONLY_ONE_ADDRESS_CAN_BE_PREFERRED: 'Solo se puede tener una dirección como preferida.',
          ONLY_ONE_ADDRESS_CAN_BE_ADDED: 'Solo se puede agregar una dirección para cada tipo. Si se selecciona el tipo de institución, solo se permite esa dirección.',
          AT_LEAST_ONE_ADDRESS_SHOULD_BE_ADDED: 'Se debe agregar al menos una dirección.',
          SORRY_WE_CANT_REMOVE_PREFERRED_ADDRESS: 'Lo sentimos, no podemos eliminar la dirección preferida.',
          SORRY_YOU_CANT_ADD_MULTIPLE_ADDRESSES_WITH_INSTITUTION_TYPE: 'Lo sentimos, si usted elige una dirección de tipo Institución no puede añadir otras direcciones.',
          SORRY_YOU_CANT_ADD_MULTIPLE_ADDRESSES_OF_SAME_TYPE: 'Lo sentimos, no puede agregar varias direcciones del mismo tipo.',
        },
        ORDER: {
          ORDER_PRODUCTS: 'Pedir productos',
          SELECT_ORDER_TYPE: 'Seleccione ordenar manualmente por teléfono/aplicación móvil o crear una entrega mensual recurrente.',
          ORDER_MANUALLY: 'Ordene manualmente por teléfono o aplicación móvil',
          ORDER_MONTHLY: 'Programar una entrega mensual recurrente',
          BENEFIT: 'Beneficio',
          SELECTED_PRODUCTS_TOTALS: 'Total de productos seleccionados',
          SELECTED_PRODUCTS_LIST: 'Lista de productos seleccionados',
          NO_PRODUCTS_SELECTED: '¡No se seleccionó ningún producto!',
          TOTAL_COUNT: 'Articulos',
          TOTAL_COST: 'Monto Total',
          PRODUCTS_COST: 'Productos',
          TOTAL_TAX: 'IVU',
          VIEW_SELECTED_PRODUCTS_LIST: 'Ver lista de productos seleccionados',
          CREATE_YOUR_MONTHLY_SHIPMENT: 'Crea tu Envío Mensual Recurrente',
          ADD_PRODUCTS_WITHOUT_EXCEEDING_YOUR_LIMIT: 'Agrega productos sin exceder su límite.',
          YOU_HAVE_REQUESTED_ITEMS_THAT_EXCEED_YOUR_BENEFITS_AMOUNT: 'Ha solicitado artículos que superan el monto de su beneficio. Remueva productos de su seleccion para continuar.',
          NO_ITEMS_SELECTED: '¡Ningún artículo fue seleccionado! seleccione al menos uno para completar su suscripción.',
        },
        DONE: {
          YOU_ARE_SET: '¡Exito. Su registro ha sido completado!',
          WHATS_NEXT: '¿Qué es lo próximo?',
          CONGRATULATIONS_YOU_HAVE_BEEN_SUCCESSFULLY_REGISTERED: '¡Felicidades! Te has registrado exitosamente.',
          THANK_YOU_FOR_YOUR_TIME: 'Gracias por su tiempo. Siéntase libre de navegar a otra página en cualquier momento, o puede iniciar sesión desde ',
          HERE: 'aquí',
        },
      },
    },
  },
};

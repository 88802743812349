import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {MATERIAL_SANITY_CHECKS} from '@angular/material/core';
import {TranslateModule} from '@ngx-translate/core';
import {InlineSVGModule} from 'ng-inline-svg-2';
import {NgxMaskModule} from 'ngx-mask';
import {CoreState} from './store';
import {TranslationService} from './i18n';
import {AuthInterceptor, AuthService} from './auth';
import {NavigationService, UserService} from './services';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    MatDialogModule,
    TranslateModule.forRoot(),
    InlineSVGModule.forRoot(),
    NgxMaskModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: MATERIAL_SANITY_CHECKS,
      useValue: false,
    },
    {
      provide: MatDialogRef,
      useValue: {},
    },
    CoreState,
    TranslationService,
    AuthService,
    UserService,
    NavigationService,
  ],
})
export class CoreModule {}

import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../environments/environment';
import {LOCAL_STORAGE} from '../../providers';
import {LanguageSymbol} from '../i18n';
import {IUser} from '../models';

const LOCAL_STORAGE_KEY = environment.LOCAL_STORAGE_KEY;

export interface ICoreState {
  language: LanguageSymbol;
  appId: string;
  token: string;
  user: Partial<IUser>;
}

@Injectable()
export class CoreState {
  private readonly _state = new BehaviorSubject<ICoreState>({
    language: this.localStorage.getItem(LOCAL_STORAGE_KEY.LANGUAGE) as LanguageSymbol,
    appId: this.localStorage.getItem(LOCAL_STORAGE_KEY.APP_ID) || '',
    token: this.localStorage.getItem(LOCAL_STORAGE_KEY.TOKEN) || '',
    user: {},
  });

  constructor(@Inject(LOCAL_STORAGE) private localStorage: Storage) {}

  get(): BehaviorSubject<ICoreState> {
    return this._state;
  }

  set(key: keyof ICoreState, value: any, storageKey?: string): void {
    if(storageKey) this.localStorage.setItem(storageKey, value);

    this._state.next({ ...this._state.value, [key]: value });
  }
}

import {LanguageSymbol} from '../../enums';
// Spain
export const locale = {
  lang: LanguageSymbol.SPANISH,
  data: {
    APP: {
      STEPPER_TITLE: 'Proceso de Inscripción',
      GENERAL: {
        LANGUAGE: 'Lenguaje',
        ENGLISH: 'English',
        SPANISH: 'Español',
        AND: 'y',
        INFORMATION_WITH_ASTERISK: 'La información marcada con un asterisco (*) es requerida.',
        BACK: 'Regresar',
        CONTINUE: 'Continuar',
        ADD: 'Añadir',
        UPDATE: 'Actualizar',
        EDIT: 'Editar',
        REMOVE: 'Remover',
        CLOSE: 'Salir',
        OK_GOT_IT: '¡Ok, entendido!',
      },
      SIGNUP: {
        FIELD: {
          NO_CARDHOLDER_NUMBER: 'No tengo la información disponible; procedo a continuar en completar la proceso de registro.',
          CARDHOLDER_NUMBER: 'Número de la tarjeta',
          DATE_OF_BIRTH: 'Fecha de nacimiento',
          FIRST_NAME: 'Nombre',
          SECOND_NAME: 'Segundo Nombre',
          LAST_NAME: 'Apellido Paterno',
          MOTHER_NAME: 'Apellido Materno',
          GENDER: 'Género',
          MALE: 'Hombre',
          FEMALE: 'Mujer',
          AGE: 'Edad',
          CELL_NUMBER: 'Número de Celular',
          TEXTS_TO_PHONE: '¿Aceptas mensajes de texto?',
          HOME_NUMBER: 'Número del Hogar',
          FAX_NUMBER: 'Número del Fax',
          DELIVERY_DATE: 'Fecha esperada de Entrega',
          EMAIL: 'Correo Electrónico',
          PREFERRED_METHOD: 'Método Preferido',
          PHONE_CALL: 'Llamada Telefónica',
          TEXT_MESSAGE: 'Mensaje de Texto',
          GROUP_ID: 'ID del Grupo:',
          GROUP_NAME: 'Nombre del Grupo:',
          HEIGHT: 'Altura:',
          FT: 'Pies',
          IN: 'Pulgadas',
          WEIGHT: 'Peso:',
          LB: 'Libras',
          ANY_ALLERGIES: 'Marque aquí si tiene algún tipo de alergia.',
          ALLERGIES: 'Alergias',
          ALLERGIES_PLACEHOLDER: 'Favor entrar los detalles de alergias.',
          NO_CONTACT: 'Favor desmarcar el encasillado si desea añadir información de contacto adicional.',
          RELATIONSHIP: 'Relación',
          AUNT: 'Tía',
          COUSIN: 'Primo',
          DAUGHTER: 'Hija',
          FATHER: 'Padre',
          HUSBAND: 'Esposo',
          MOTHER: 'Madre',
          OTHER: 'Otro',
          SON: 'Hijo',
          UNCLE: 'Tío',
          WIFE: 'Esposa',
          EMERGENCY: 'Emergencia',
          PHI_AUTHORIZED: 'PHI Autorizado',
          NO_INSURANCE: 'No tengo otro seguro y/o asistencia.',
          INSURANCE_TYPE: 'Seleccione el tipo de pagador',
          PATIENT_ASSISTANCE: 'Programa de Ayuda Financiera',
          OTHER_INSURANCE: 'Otro Plan Médico',
          INSURANCE_NAME: 'Nombre del Seguro',
          SELECT_INSURANCE: 'Nombre del Seguro',
          COMMERCIAL: 'Comercial',
          PLAN_VITAL: 'Plan Vital (Reforma)',
          INSURANCE_ID: 'ID del Plan Médico y/o Asistencia al Paciente',
          HEALTH_PLAN_PHOTO: 'Foto del plan médico y/o programa de asistencia',
          DELIVER_TO_DOCTOR: 'Entregar medicamentos al consultorio del médico.',
          ADDRESS_TYPE: 'Tipo de Dirección',
          HOME: 'Hogar',
          WORK: 'Trabajo',
          URBANIZATION: 'Urbanización',
          ADDRESS_1: 'Dirección - Línea 1',
          ADDRESS_2: 'Dirección - Línea 2',
          CITY: 'Ciudad',
          STATE: 'Estado',
          ZIP_CODE: 'Código Postal (+ 4)',
          EXPECTED_DELIVERY_DATE: 'Fecha esperada de entrega',
          DELIVERY_INSTRUCTIONS: 'Instrucciones de Entrega',
          DELIVERY_INSTRUCTIONS_PLACEHOLDER: 'Favor entrar sus instrucciones de entrega.',
          PREFERRED_ADDRESS: 'Dirección Preferida',
          PREFERRED_ADDRESS_INFO: 'Solo se puede tener una dirección como preferida.',
          VALIDATION: {
            REQUIRED: '{{label}} debe ser completado',
            INVALID: '{{label}} no válida',
            MIN: '{{label}} debe ser mayor que {{min}}',
            MIN_EQUAL: '{{label}} debe ser mayor o igual a {{min}}',
          },
        },
        START_PROCESS: {
          TITLE: 'Comenzar',
          WELCOME: 'BIENVENIDO al PharmPix Specialty HUB®',
          INTRODUCTION: 'PharmPix Specialty HUB® (PSH) maneja el despacho de sus medicamentos especializados a través de un centro único (HUB) de servicio. Este Centro (HUB) le proveerá el apoyo necesario para conseguir la asistencia financiera disponible para cubrir su responsabilidad de pago de sus medicamentos especializados, facilitará el proceso de aprobación con el plan médico y coordinará la entrega de sus medicamentos a su hogar, centro de trabajo u oficina médica. El programa de PharmPix Specialty HUB® utiliza una red de farmacias especializadas en alianza con proveedores de Puerto Rico y los Estados Unidos.',
          CLICK_TO_START: 'Haz click aquí para comenzar!',
          BY_STARTING_PROCESS: 'Al comenzar el proceso estás aceptando nuestros',
          TERMS_OF_SERVICES: 'Términos de Servicio',
          PRIVACY_POLICY: 'Política de Privacidad',
        },
        CHECK_ELIGIBILITY: {
          TITLE: 'Verificación de Elegibilidad',
          CHECK_IF_ELIGIBLE: 'Verificar si el paciente es elegible para este programa.',
        },
        PATIENT_INFO: {
          TITLE: 'Información del Paciente',
          ADD_PATIENT: 'Añade tu información de paciente.',
        },
        CONTACT_INFO: {
          TITLE: 'Información de Contacto',
          LONG_TITLE: 'Información de Contacto Autorizada',
          ADD_CONTACT: 'Añade tu información de contacto adicional.',
        },
        INSURANCE: {
          TITLE: 'Seguro Médico Adicional',
          LONG_TITLE: 'Información de Otros Seguros Médicos y Asistencia al Paciente',
          ADD_INSURANCE: 'Agregar seguro o programa de asistencia adicional al paciente',
          LONG_ADD_INSURANCE: 'Agregue los detalles de cualquier otro seguro adicional (que no sea MCS) o programas de asistencia al paciente en los que esté inscrito.',
          AT_LEAST_ONE: 'Se debe agregar al menos un Seguro o Programa de Asistencia.',
        },
        DELIVERY_ADDRESS: {
          TITLE: 'Dirección de Entrega',
          ADD_ADDRESS: 'Añada su dirección (es) de entrega (s).',
          ADD_NEW_ADDRESS: 'Agregar nueva dirección de entrega',
          AT_LEAST_ONE: 'Se debe agregar al menos una dirección.',
        },
        END_PROCESS: {
          TITLE: 'Inscripción Completada',
          THANK_YOU: 'Gracias por completar su proceso de Inscripción!',
          EMAIL_WITH_DETAILS: 'Recibirás un correo electrónico con el detalle de su registro.',
          ALREADY_ENROLLED: 'Estimado afiliado, nuestros registros muestran que has completado tu proceso de registro',
        },
      },
    },
  },
};

import {LanguageSymbol} from '../../enums';
// Spain
export const locale = {
  lang: LanguageSymbol.SPANISH,
  data: {
    APP: {
      NAME: 'PORTAL',
      GENERAL: {
        LANGUAGE: 'Lenguaje',
        ENGLISH: 'English',
        SPANISH: 'Spanish',
        AND: 'y',
        EDIT: 'Editar',
        REMOVE: 'Remover',
        BACK: 'Atrás',
        OK_GOT_IT: '¡Ok, entendido!',
        CONTINUE: 'Continuar',
        IGNORE: 'Ignorar',
        SUBMIT: 'Enviar',
        CONFIRM: 'Confirmar',
        FINISH: 'Terminar',
        ADD: 'Añadir',
        UPDATE: 'Actualizar',
      },
      SIGNUP: {
        FIELD: {
          ZIPCODE: 'Código Postal',
          EMPLOYEE_NUMBER_OR_MEMBERSHIP_ID: 'Número de empleado o identificación de membresía',
          REFERRAL_CODE: 'Código de referido',
          GIVEN_NAME: 'Nombre',
          SECOND_NAME: 'Segundo nombre',
          FAMILY_NAME: 'Apellido paterno',
          MOTHERS_MAIDEN_FAMILY_NAME: 'Apellido materno',
          DATE_OF_BIRTH: 'Fecha de nacimiento',
          GENDER: 'Género',
          CELL_NUMBER: 'Teléfono móvil',
          HOME_NUMBER: 'Teléfono del hogar',
          FAX_NUMBER: 'Teléfono de fax',
          EMAIL: 'Correo electrónico',
          USERNAME: 'Nuevo nombre de usuario',
          PASSWORD: 'Nueva contraseña',
          RE_PASSWORD: 'Vuelva a introducir la contraseña',
          DELIVERY_ZIPCODE: 'Código postal de lugar de entrega',
          HOME: 'Casa',
          OFFICE: 'Oficina',
          OTHER: 'Otro',
          URBANIZATION: 'Urbanización',
          ADDRESS_LINE1: 'Dirección línea 1',
          ADDRESS_LINE2: 'Dirección línea 2',
          CITY: 'Ciudad',
          STATE: 'Estado',
          ZIPCODE_4CODE: 'Código Postal + 4Code',
          DELIVERY_INSTRUCTIONS: 'Instrucciones de entrega',
          PRESS123_AT_FRONT_GATE: 'Presione \'123\' en la puerta principal.',
          PREFERRED_ADDRESS: 'Dirección preferida',
          TYPE_OF_ADDRESS: 'Tipo de dirección',
          PRIMARY_INSURED_RELATION: 'Relación con el asegurado principal',
          PRIMARY_INSURED_NAME: 'Nombre del asegurado principal',
          CONTRACT_NUMBER: 'Número de contrato',
          RX_GROUP: 'Grupo Rx',
          FRONT_IMAGE_INSURANCE_CARD: 'Imagen frontal de la tarjeta de seguro de salud',
          BACK_IMAGE_INSURANCE_CARD: 'Imagen lateral de la tarjeta de seguro de salud',
          GOVERNMENT_ID_NUMBER: 'Número de la ID gubernamental',
          GOVERNMENT_ID_STATE: 'Estado de la ID gubernamental',
          IMAGE_GOVERNMENT_ID_CARD: 'Imagen de la tarjeta de ID gubernamental',
          PATIENT_SMS: 'SMS del paciente (Mensaje de texto)',
          PATIENT_EMAIL: 'Correo electrónico del paciente',
          PATIENT_PHONE_CALL: 'Llamada telefónica del paciente',
          PATIENT_MAIL: 'Patient Mail',
          CAREGIVER_SMS: 'SMS del cuidador (Mensaje de texto)',
          CAREGIVER_EMAIL: 'Correo electrónico del cuidador',
          CAREGIVER_PHONE_CALL: 'Llamada telefónica del cuidador',
          CAREGIVER_MAIL: 'Caregiver Mail',
          RELATIONSHIP_WITH_THE_CAREGIVER: 'Relación con el cuidador',
          EMPLOYER_NAME: 'Nombre del empleado',
          RELATIONSHIP_WITH_THE_PATIENT: 'Relación con el paciente',
          CONFIRMATION_CODE: 'Código de confirmación',
          CODE: 'Código',
          VALIDATION: {
            REQUIRED: '{{label}} debe ser completada',
            INVALID: '{{label}} no válida',
            NO_WHITESPACE: '{{label}} no válida: No se permiten espacios en blanco',
            MIN: '{{label}} debe ser mayor que {{min}}',
            EXISTS: '{{label}} ya está en uso',
            PATTERN: '{{label}} debe coincidir con el patrón',
            PW_MATCH: 'Las contraseñas no coinciden',
          },
        },
        CAREGIVER: {
          YOU_ARE_REQUESTING_A_NEW_ACCOUNT_FOR_SOMEONE_ELSE_AS_THEIR_CAREGIVER: 'Estás solicitando una cuenta nueva para otra persona como su cuidador',
          ID_INFORMATION: 'Información de identificación',
          ADD_YOUR_ID_INFORMATION_TO_RECEIVE_PRESCRIPTIONS: 'Añada su información de identificación para recibir recetas.',
          I_PLAN_TO_RECEIVE_PRESCRIPTIONS_ON_BEHALF_OF_A_PATIENT: 'Planeo recibir recetas en nombre de un paciente',
          IF_YOU_PLAN_TO_RECEIVE_PRESCRIPTIONS_ON_BEHALF_OF_A_PATIENT: 'Si planea recibir recetas en nombre de un paciente, incluya una identificación emitida por el gobierno y cargue una foto de esa identificación.',
        },
        USER_TYPE: {
          USER_TYPE: 'Tipo de usuario',
          SELECT_WHAT_DESCRIBES_YOU_BEST: 'Seleccione lo que mejor le describa.',
          REGISTRATION_PROCESS_MAY_DIFFER_BASED_ON_YOUR_SELECTION: 'El proceso de registro pudiera diferir según su selección.',
          IM_A_PATIENT: 'Soy un paciente',
          I_WANT_TO_REGISTER_FOR_MY_PERSONAL_USE: 'Quiero registrarme para mi uso personal',
          IM_A_CAREGIVER: 'Soy cuidador',
          I_WANT_TO_REGISTER_PEOPLE_THAT_I_CARE_FOR: 'Quiero registrar a las personas que cuido',
          IM_A_WELLNESS_PROGRAM_PATIENT: 'Soy un paciente del programa de bienestar',
          I_WANT_TO_REGISTER_THROUGH_MY_EMPLOYERS_EMPLOYEE_WELLNESS_PROGRAM: 'Quiero registrarme a través del Programa de Bienestar para Empleados de mi empleador',
          BY_MAKING_A_SELECTION_YOU_AGREE_WITH_OUR: 'Al hacer una selección, usted está de acuerdo con nuestro',
          TERMS_OF_SERVICES: 'Términos de Servicios',
          PRIVACY_POLICY: 'Política de Privacidad',
        },
        CHECK_SERVICE: {
          SERVICE_AVAILABILITY: 'Servicio disponible',
          TELL_US_WHERE_YOU_WOULD_LIKE_TO_RECEIVE_YOUR_MEDICATIONS: 'Díganos dónde le gustaría recibir sus medicamentos.',
          SERVICE_AREA_IS_CURRENTLY_AVAILABLE_ONLY_IN_PUERTO_RICO: 'El área de servicio está actualmente disponible solo en Puerto Rico.',
          CHECK_IF_THE_SERVICE_AVAILABLE_FOR_YOU: 'Verifique si el servicio está disponible para usted',
        },
        BASIC_INFO: {
          BASIC_INFORMATION: 'Información básica',
          ADD_YOUR_BASIC_INFORMATION: 'Agrega tu información básica.',
          ADD_PATIENT_BASIC_INFORMATION: 'Agregar información básica del paciente.',
          ALREADY_REGISTERED_WITH_USER_1: '¿Ya estas registrado? Si es así, haga clic en ',
          ALREADY_REGISTERED_WITH_USER_2: ' para redirigirlo a la página de inicio de sesión donde podra administrar su cuenta.',
          LOGIN: 'iniciar sesión',
          ALREADY_REGISTERED_WITH_CONTACT: 'Parece que ya estás registrado. Por Favor contacte el centro de llamadas de Medplus para solicitar ayuda.',
        },
        NO_SERVICE: {
          NO_SERVICE: 'Sin servicio',
          WE_ARE_SORRY_OUR_SERVICE_IS_NOT_AVAILABLE_IN_YOUR_AREA: 'Lo sentimos, nuestro servicio no está disponible en su área.',
          WE_ARE_SORRY_WE_COULD_NOT_FIND_YOUR_MEMBERSHIP_AT_THIS_TIME: 'Lo sentimos, no pudimos encontrar su membresía en este momento.',
          WOULD_YOU_LIKE_TO_ENTER_YOUR_INFORMATION_SO_WE_CAN_LET_YOU_KNOW_WHEN_IS_AVAILABLE: '¿Le gustaría ingresar su información para que podamos informarle cuándo esté disponible?',
          WOULD_YOU_LIKE_TO_ENTER_YOUR_INFORMATION: '¿Te gustaría ingresar tus datos?',
          CONTACT_INFORMATION: 'Información de contacto',
        },
        CREATE_ACCOUNT: {
          CREATE_ACCOUNT: 'Crear cuenta',
          ADD_YOUR_USERNAME_AND_PASSWORD: 'Agregue su usuario y contraseña',
          ADD_PATIENT_USERNAME_AND_PASSWORD: 'Agregar nombre de usuario y contraseña del paciente.',
          GREAT_NEWS_OUR_SERVICE_IS_AVAILABLE_IN_YOUR_AREA: '¡Buenas noticias! Nuestro servicio está disponible en su área.',
          YOUR_CELLPHONE_IS_NOT_CONFIRMED: 'Su teléfono celular no está confirmado. Por favor ingrese el código que recibió ',
          HERE: 'aquí',
          ENTER_CONFIRMATION_CODE: 'Introducir el código de confirmación',
          ENTER_THE_CODE_WE_SENT_TO: 'Ingresa el código de 6 dígitos que enviamos a ',
          REQUEST_NEW_CODE: 'Solicitar nuevo código',
          MIN_8CHARS: '8 caracteres o más',
          MIN_1UPPERCASE: 'Al menos una (1) letra mayúscula',
          MIN_1NUMBER: 'Al menos un (1) número',
          MIN_1SPECIAL: 'Al menos un (1) carácter especial',
        },
        DELIVERY_ADDRESS: {
          DELIVERY_ADDRESS: 'Dirección de entrega',
          ADD_YOUR_DELIVERY_ADDRESS: 'Añade tus direcciones de entrega.',
          ADD_PATIENT_DELIVERY_ADDRESS: 'Añadir direcciónes de entrega del paciente.',
          YOUR_DELIVERY_ADDRESSES: 'Sus direcciones de entrega',
          PATIENT_DELIVERY_ADDRESSES: 'Dirección de entrega del paciente',
          ADD_NEW_ADDRESS: 'Añadir una nueva dirección',
          NO_ADDRESS_ADDED: '¡No se añadió ninguna dirección!',
          ONLY_ONE_ADDRESS_CAN_BE_PREFERRED: 'Solo se puede tener una dirección como preferida.',
          SORRY_WE_CANT_REMOVE_PREFERRED_ADDRESS: 'Lo sentimos, no podemos eliminar la dirección preferida.',
        },
        OTHER_INFO: {
          OTHER_INFORMATION: 'Otra información',
          ADD_YOUR_OTHER_INFORMATION: 'Agregue su otra información (seguro, identificación y preferencias de comunicación).',
          ADD_PATIENT_OTHER_INFORMATION: 'Agregue otra información del paciente (seguro, identificación y preferencias de comunicación).',
          INSURANCE_INFORMATION: 'Información del seguro',
          ID_INFORMATION: 'Información de identificación',
          COMMUNICATION_PREFERENCES: 'Preferencia de comunicacion',
        },
        SUBSCRIPTION: {
          SUBSCRIPTION: 'Suscripción',
          CHOOSE_YOUR_SUBSCRIPTION_PLAN_AND_PREFERRED_PAYMENT_METHOD: 'Elija su plan de suscripción y método de pago preferido.',
          CHOOSE_PATIENT_SUBSCRIPTION_PLAN_AND_PREFERRED_PAYMENT_METHOD: 'Elija el plan de suscripción del paciente y el método de pago preferido.',
        },
        YOU_ARE_SET: {
          YOU_ARE_SET: '¡Exito. Su registro ha sido completado!',
          WHATS_NEXT: '¿Qué es lo próximo?',
          ADD_HERE_FAMILY_MEMBERS_OR_ANYONE_UNDER_YOUR_CARE: 'Añada aquí miembros de su familia o cualquier otra persona bajo su cuidado.',
          YOU_CAN_ADD_OTHER_PEOPLE_OR_INVITE_SOMEONE: 'Puede agregar a otras personas que usted este cuidando y administrar su cuenta o puede invitar a alguien que lo esté cuidando a que se registre y administre su cuenta por usted.',
          I_WANT_TO_ADD_SOMEONE_IAM_CARING_FOR: 'Quiero agregar a alguien a quien estoy cuidando',
          I_WANT_TO_INVITE_SOMEONE_TO_CARE_FOR_ME: 'Quiero invitar a alguien a cuidarme',
        },
        CARETAKERS: {
          CARETAKERS: 'Cuidadores',
          ADD_HERE_FAMILY_MEMBERS_OR_ANYONE_UNDER_YOUR_CARE: 'Añada aquí miembros de su familia o cualquier otra persona bajo su cuidado.',
          ADD_HERE_FAMILY_MEMBERS_OR_ANYONE_UNDER_YOUR_CARE_FULL: 'Añada aquí miembros de su familia o cualquier otra persona bajo su cuidado para que pueda suscribirlos al servicio ME y administrar su cuenta.',
          ADD_PEOPLE_UNDER_YOUR_CARE: 'Añadir personas bajo su cuidado',
          PEOPLE_UNDER_YOUR_CARE: 'Personas bajo su cuidado',
          NO_ONE_FOUND: '¡No se encontró a nadie!',
          PENDING: 'Pendiente',
          APPROVED: 'Aprobado',
        },
        INVITE_CAREGIVER: {
          INVITE_CAREGIVER: 'Invitar al cuidador',
          YOU_ARE_INVITING_SOMEONE_ELSE_AS_YOUR_CAREGIVER: 'Estás invitando a otra persona, como tu cuidador',
          ADD_YOUR_CAREGIVER_BASIC_INFORMATION: 'Agregue la información básica de su cuidador.',
          CAREGIVER_BASIC_INFORMATION: 'Información básica del cuidador',
        },
        INVITATION_SENT: {
          INVITATION_SENT: '¡Invitación enviada!',
          WHATS_NEXT: '¿Qué es lo próximo?',
          YOUR_INVITATION_REQUEST_HAS_BEEN_SENT_SUCCESSFULLY: 'Su solicitud de invitación ha sido enviada con éxito',
          THE_CAREGIVER_YOU_INVITED_WILL_RECEIVE_EMAIL_OR_TEXT: 'El cuidador que invitó recibirá un correo electrónico o un mensaje de texto solicitándole que se una a ME como su cuidador. Una vez que acepten su invitación, recibirás una notificación.',
        },
      },
    },
  },
};

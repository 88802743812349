import {LanguageSymbol} from '../../enums';
// USA
export const locale = {
  lang: LanguageSymbol.ENGLISH,
  data: {
    APP: {
      NAME: 'PORTAL',
      GENERAL: {
        LANGUAGE: 'Language',
        AND: 'and',
        EDIT: 'Edit',
        REMOVE: 'Remove',
        BACK: 'Back',
        OK_GOT_IT: 'OK, Got it!',
        CONTINUE: 'Continue',
        IGNORE: 'Ignore',
        SUBMIT: 'Submit',
        FINISH: 'Finish',
        CLOSE: 'Close',
        ADD: 'Add',
        UPDATE: 'Update',
        COMPLETE_SUBSCRIPTION: 'Complete Subscription',
      },
      SIGNUP: {
        FIELD: {
          ZIPCODE: 'Zip Code',
          MEMBERSHIP_ID: 'Membership ID',
          REFERRAL_CODE: 'Referral Code',
          GIVEN_NAME: 'Given Name',
          SECOND_NAME: 'Second Name',
          FAMILY_NAME: 'Family Name',
          MOTHERS_MAIDEN_FAMILY_NAME: 'Mother\'s Maiden Family Name',
          DATE_OF_BIRTH: 'Date of Birth',
          GENDER: 'Gender',
          CELL_NUMBER: 'Cell Number',
          HOME_NUMBER: 'Home Number',
          FAX_NUMBER: 'Fax Number',
          EMAIL: 'Email',
          USERNAME: 'Username',
          PASSWORD: 'Password',
          RE_PASSWORD: 'Re-Password',
          DELIVERY_ZIPCODE: 'Delivery Zip Code',
          HOME: 'Home',
          OFFICE: 'Office',
          OTHER: 'Other',
          URBANIZATION: 'Urbanization',
          ADDRESS_LINE1: 'Address line 1',
          ADDRESS_LINE2: 'Address line 2',
          CITY: 'City',
          STATE: 'State',
          ZIPCODE_4CODE: 'Zip Code + 4Code',
          DELIVERY_INSTRUCTIONS: 'Delivery instructions',
          PRESS123_AT_FRONT_GATE: 'Press \'123\' at front gate.',
          PREFERRED_ADDRESS: 'Preferred Address',
          TYPE_OF_ADDRESS: 'Type of Address',
          PRIMARY_INSURED_RELATION: 'Primary Insured Relation',
          PRIMARY_INSURED_NAME: 'Primary Insured Name',
          CONTRACT_NUMBER: 'Contract Number',
          RX_GROUP: 'Rx Group',
          FRONT_IMAGE_INSURANCE_CARD: 'Front Image of Insurance Card',
          BACK_IMAGE_INSURANCE_CARD: 'Back Image of Insurance Card',
          GOVERNMENT_ID_NUMBER: 'Government ID Number',
          GOVERNMENT_ID_STATE: 'Government ID State',
          IMAGE_GOVERNMENT_ID_CARD: 'Image of Government ID Card',
          PATIENT_SMS: 'Patient SMS (Text message)',
          PATIENT_EMAIL: 'Patient Email',
          PATIENT_PHONE_CALL: 'Patient Phone Call',
          PATIENT_MAIL: 'Patient Mail',
          CATEGORY: 'Category',
          IMAGE: 'Image',
          SKU: 'SKU',
          NAME: 'Name',
          COUNT: 'Count',
          PRICE: 'Price',
          VALIDATION: {
            REQUIRED: '{{label}} is required',
            INVALID: 'Invalid {{label}}',
            NO_WHITESPACE: 'Invalid {{label}}: Whitespaces are not allowed',
            MIN: '{{label}} must be greater than {{min}}',
            EXISTS: '{{label}} already in use',
            PATTERN: '{{label}} must match the pattern',
            PW_MATCH: 'Passwords don\'t match',
          },
        },
        PROGRAM_TYPE: {
          SALUS_PROGRAMS: 'Salus Programs',
          SELECT_PROGRAM_YOU_WANT_TO_REGISTER_FOR: 'Select program you want to register for.',
          REGISTRATION_PROCESS_MAY_DIFFER_BASED_ON_YOUR_SELECTION: 'Registration process may differ based on your selection.',
          LIVING_HEALTH_PROGRAM: 'Living Health Program',
          REGISTER_FOR_THE_SALUS_LIVING_HEALTH_PROGRAM: 'Register for the Salus Living Health Program',
          WELLNESS_PROGRAM: 'Wellness Program',
          REGISTER_FOR_THE_SALUS_WELLNESS_PROGRAM: 'Register for the Salus Wellness Program',
          MEDICATION_MANAGEMENT_PROGRAM: 'Medication Management Program',
          REGISTER_FOR_THE_SALUS_MEDICATION_MANAGEMENT_PROGRAM: 'Register for the Salus Medication Management Program',
          BY_MAKING_A_SELECTION_YOU_AGREE_WITH_OUR: 'By making a selection, you agree with our',
          TERMS_OF_SERVICES: 'Terms of Services',
          PRIVACY_POLICY: 'Privacy Policy',
        },
        CHECK_ELIGIBILITY: {
          ELIGIBILITY_CHECK: 'Eligibility Check',
          CHECK_IF_THE_PATIENT_IS_ELIGIBLE_FOR_THIS_PROGRAM: 'Check if the patient is eligible for this Program.',
          INVALID_DATA_PROVIDED: 'The data provided does not match our systems. Please check your membership ID and date of birth, then retry again.',
          ALREADY_ENROLLED: 'The patient with this membership ID has already been enrolled. Please contact customer support for any modifications.',
        },
        BASIC_INFO: {
          BASIC_INFORMATION: 'Basic Information',
          ADD_YOUR_BASIC_INFORMATION: 'Add your basic information.',
          ALREADY_EXIST_PART_1: 'A contact with this phone number already exists. Please attempt to ',
          ALREADY_EXIST_PART_2: ' with your Medplus User ID or if you believe this is an error, please contact our help desk.',
          LOGIN: 'login',
        },
        CREATE_ACCOUNT: {
          CREATE_ACCOUNT: 'Create Account',
          ADD_YOUR_USERNAME_AND_PASSWORD: 'Add your username and password.',
          GREAT_NEWS: 'Great News! You are eligible for this program, please add your username and password.',
          MIN_8CHARS: '8 characters or more',
          MIN_1UPPERCASE: 'At least 1 uppercase letter',
          MIN_1NUMBER: 'At least 1 number',
          MIN_1SPECIAL: 'At least 1 special character',
        },
        DELIVERY_ADDRESS: {
          DELIVERY_ADDRESS: 'Delivery Address',
          ADD_YOUR_DELIVERY_ADDRESS: 'Add your delivery address.',
          YOUR_DELIVERY_ADDRESSES: 'Your Delivery Addresses',
          ADD_NEW_ADDRESS: 'Add a new address',
          NO_ADDRESS_ADDED: 'No address added!',
          ONLY_ONE_ADDRESS_CAN_BE_PREFERRED: 'Only one address can be preferred.',
          SORRY_WE_CANT_REMOVE_PREFERRED_ADDRESS: 'Sorry, we can\'t remove the preferred address.',
        },
        OTHER_INFO: {
          OTHER_INFORMATION: 'Other Information',
          ADD_YOUR_OTHER_INFORMATION: 'Add your other information (Insurance, ID, and Communication Preferences).',
          INSURANCE_INFORMATION: 'Insurance Information',
          ID_INFORMATION: 'ID Information',
          COMMUNICATION_PREFERENCES: 'Communication Preferences',
        },
        ORDER: {
          ORDER_DEVICES: 'Order Devices',
          SELECT_DEVICES_TO_BE_ORDERED: 'Select devices to be ordered.',
          SELECTED_DEVICES_TOTALS: 'Selected Devices Totals',
          SELECTED_DEVICES_LIST: 'Selected Devices List',
          NO_DEVICES_SELECTED: 'No Devices were Selected!',
          TOTAL_COUNT: 'Total Count',
          TOTAL_COST: 'Total Cost',
          VIEW_SELECTED_DEVICES_LIST: 'View selected devices list',
        },
        DONE: {
          YOU_ARE_SET: 'You are Set!',
          WHATS_NEXT: 'What\'s Next?',
          CONGRATULATIONS_YOU_HAVE_BEEN_SUCCESSFULLY_REGISTERED: 'Congratulations! You have been successfully registered.',
          THANK_YOU_FOR_YOUR_TIME: 'Thank you for your time. Please feel free to leave this page at any time, or you can login from ',
          HERE: 'here',
        },
      },
    },
  },
};

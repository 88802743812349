import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CoreState} from '../../store';
import {environment} from '../../../../environments/environment';
import {ILocale} from '../models';
import {LanguageSymbol} from '../enums';

const LANGUAGE_KEY = environment.LOCAL_STORAGE_KEY.LANGUAGE;

@Injectable()
export class TranslationService {
  private langIds: any = [];

  constructor(
    private translate: TranslateService,
    private coreState: CoreState,
  ) {
    this.translate.addLangs([LanguageSymbol.ENGLISH]);

    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(LanguageSymbol.ENGLISH);
  }

  loadTranslations(...args: ILocale[]): void {
    const locales = [...args];

    locales.forEach((locale) => {
      // use setTranslation() with the third argument set to true
      // to append translations instead of replacing them
      this.translate.setTranslation(locale.lang, locale.data, true);
      this.langIds.push(locale.lang);
    });

    // add new languages to the list
    this.translate.addLangs(this.langIds);
    this.translate.use(this.getSelectedLanguage());
  }

  setLanguage(lang: LanguageSymbol) {
    if(lang) {
      this.translate.use(this.translate.getDefaultLang());
      this.translate.use(lang);
      this.coreState.set('language', lang, LANGUAGE_KEY);
    }
  }

  setDefaultLanguage(lang: LanguageSymbol) {
    if(lang) this.translate.setDefaultLang(lang);
  }

  getSelectedLanguage(): any {
    return (this.coreState.get().value.language || this.translate.getDefaultLang());
  }

  instant(key: string | string[], interpolateParams?: Object | undefined): any {
    return this.translate.instant(key, interpolateParams);
  }
}

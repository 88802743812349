import {LanguageSymbol} from '../../enums';
// USA
export const locale = {
  lang: LanguageSymbol.ENGLISH,
  data: {
    APP: {
      NAME: 'PORTAL',
      GENERAL: {
        LANGUAGE: 'Language',
        ENGLISH: 'English',
        SPANISH: 'Spanish',
        AND: 'and',
        EDIT: 'Edit',
        REMOVE: 'Remove',
        BACK: 'Back',
        OK_GOT_IT: 'OK, Got it!',
        CONTINUE: 'Continue',
        IGNORE: 'Ignore',
        SUBMIT: 'Submit',
        CONFIRM: 'Confirm',
        FINISH: 'Finish',
        ADD: 'Add',
        UPDATE: 'Update',
      },
      SIGNUP: {
        FIELD: {
          ZIPCODE: 'Zip Code',
          EMPLOYEE_NUMBER_OR_MEMBERSHIP_ID: 'Employee Number or Membership ID',
          REFERRAL_CODE: 'Referral Code',
          GIVEN_NAME: 'Given Name',
          SECOND_NAME: 'Second Name',
          FAMILY_NAME: 'Family Name',
          MOTHERS_MAIDEN_FAMILY_NAME: 'Mother\'s Maiden Family Name',
          DATE_OF_BIRTH: 'Date of Birth',
          GENDER: 'Gender',
          CELL_NUMBER: 'Cell Number',
          HOME_NUMBER: 'Home Number',
          FAX_NUMBER: 'Fax Number',
          EMAIL: 'Email',
          USERNAME: 'Username',
          PASSWORD: 'Password',
          RE_PASSWORD: 'Re-Password',
          DELIVERY_ZIPCODE: 'Delivery Zip Code',
          HOME: 'Home',
          OFFICE: 'Office',
          OTHER: 'Other',
          URBANIZATION: 'Urbanization',
          ADDRESS_LINE1: 'Address line 1',
          ADDRESS_LINE2: 'Address line 2',
          CITY: 'City',
          STATE: 'State',
          ZIPCODE_4CODE: 'Zip Code + 4Code',
          DELIVERY_INSTRUCTIONS: 'Delivery instructions',
          PRESS123_AT_FRONT_GATE: 'Press \'123\' at front gate.',
          PREFERRED_ADDRESS: 'Preferred Address',
          TYPE_OF_ADDRESS: 'Type of Address',
          PRIMARY_INSURED_RELATION: 'Primary Insured Relation',
          PRIMARY_INSURED_NAME: 'Primary Insured Name',
          CONTRACT_NUMBER: 'Contract Number',
          RX_GROUP: 'Rx Group',
          FRONT_IMAGE_INSURANCE_CARD: 'Front Image of Insurance Card',
          BACK_IMAGE_INSURANCE_CARD: 'Back Image of Insurance Card',
          GOVERNMENT_ID_NUMBER: 'Government ID Number',
          GOVERNMENT_ID_STATE: 'Government ID State',
          IMAGE_GOVERNMENT_ID_CARD: 'Image of Government ID Card',
          PATIENT_SMS: 'Patient SMS (Text message)',
          PATIENT_EMAIL: 'Patient Email',
          PATIENT_PHONE_CALL: 'Patient Phone Call',
          PATIENT_MAIL: 'Patient Mail',
          CAREGIVER_SMS: 'Caregiver SMS (Text message)',
          CAREGIVER_EMAIL: 'Caregiver Email',
          CAREGIVER_PHONE_CALL: 'Caregiver Phone Call',
          CAREGIVER_MAIL: 'Caregiver Mail',
          RELATIONSHIP_WITH_THE_CAREGIVER: 'Relationship with the Caregiver',
          EMPLOYER_NAME: 'Employer\'s Name',
          RELATIONSHIP_WITH_THE_PATIENT: 'Relationship with the Patient',
          CONFIRMATION_CODE: 'Confirmation Code',
          CODE: 'Code',
          VALIDATION: {
            REQUIRED: '{{label}} is required',
            INVALID: 'Invalid {{label}}',
            NO_WHITESPACE: 'Invalid {{label}}: Whitespaces are not allowed',
            MIN: '{{label}} must be greater than {{min}}',
            EXISTS: '{{label}} already in use',
            PATTERN: '{{label}} must match the pattern',
            PW_MATCH: 'Passwords don\'t match',
          },
        },
        CAREGIVER: {
          YOU_ARE_REQUESTING_A_NEW_ACCOUNT_FOR_SOMEONE_ELSE_AS_THEIR_CAREGIVER: 'You\'re requesting a new account for someone else, as their Caregiver',
          ID_INFORMATION: 'ID Information',
          ADD_YOUR_ID_INFORMATION_TO_RECEIVE_PRESCRIPTIONS: 'Add your ID information to receive prescriptions.',
          I_PLAN_TO_RECEIVE_PRESCRIPTIONS_ON_BEHALF_OF_A_PATIENT: 'I plan to receive prescriptions on behalf of a Patient',
          IF_YOU_PLAN_TO_RECEIVE_PRESCRIPTIONS_ON_BEHALF_OF_A_PATIENT: 'If you plan to receive prescriptions on behalf of a Patient, please include a government-issued ID and upload a photo of that ID.',
        },
        USER_TYPE: {
          USER_TYPE: 'User Type',
          SELECT_WHAT_DESCRIBES_YOU_BEST: 'Select what describes you best.',
          REGISTRATION_PROCESS_MAY_DIFFER_BASED_ON_YOUR_SELECTION: 'Registration process may differ based on your selection.',
          IM_A_PATIENT: 'I\'m a Patient',
          I_WANT_TO_REGISTER_FOR_MY_PERSONAL_USE: 'I want to register for my personal use',
          IM_A_CAREGIVER: 'I\'m a Caregiver',
          I_WANT_TO_REGISTER_PEOPLE_THAT_I_CARE_FOR: 'I want to register people that I care for',
          IM_A_WELLNESS_PROGRAM_PATIENT: 'I\'m a Wellness Program Patient',
          I_WANT_TO_REGISTER_THROUGH_MY_EMPLOYERS_EMPLOYEE_WELLNESS_PROGRAM: 'I want to register through my employer\'s employee wellness program',
          BY_MAKING_A_SELECTION_YOU_AGREE_WITH_OUR: 'By making a selection, you agree with our',
          TERMS_OF_SERVICES: 'Terms of Services',
          PRIVACY_POLICY: 'Privacy Policy',
        },
        CHECK_SERVICE: {
          SERVICE_AVAILABILITY: 'Service Availability',
          TELL_US_WHERE_YOU_WOULD_LIKE_TO_RECEIVE_YOUR_MEDICATIONS: 'Tell us where you would like to receive your medications.',
          SERVICE_AREA_IS_CURRENTLY_AVAILABLE_ONLY_IN_PUERTO_RICO: 'Service area is currently available only in Puerto Rico.',
          CHECK_IF_THE_SERVICE_AVAILABLE_FOR_YOU: 'Check if the service available for you',
        },
        BASIC_INFO: {
          BASIC_INFORMATION: 'Basic Information',
          ADD_YOUR_BASIC_INFORMATION: 'Add your basic information.',
          ADD_PATIENT_BASIC_INFORMATION: 'Add patient\'s basic information.',
          ALREADY_REGISTERED_WITH_USER_1: 'Are you already registered? If so, click',
          ALREADY_REGISTERED_WITH_USER_2: 'to be redirected to the login page where you can manage your account.',
          LOGIN: 'login',
          ALREADY_REGISTERED_WITH_CONTACT: 'It seems that you are already registered. Please contact the Medplus call center for help.',
        },
        NO_SERVICE: {
          NO_SERVICE: 'No Service',
          WE_ARE_SORRY_OUR_SERVICE_IS_NOT_AVAILABLE_IN_YOUR_AREA: 'We are sorry, our service is not available in your area.',
          WE_ARE_SORRY_WE_COULD_NOT_FIND_YOUR_MEMBERSHIP_AT_THIS_TIME: 'We are sorry, we could not find your membership at this time.',
          WOULD_YOU_LIKE_TO_ENTER_YOUR_INFORMATION_SO_WE_CAN_LET_YOU_KNOW_WHEN_IS_AVAILABLE: 'Would you like to enter your information so we can let you know when is available?',
          WOULD_YOU_LIKE_TO_ENTER_YOUR_INFORMATION: 'Would you like to enter your information?',
          CONTACT_INFORMATION: 'Contact Information',
        },
        CREATE_ACCOUNT: {
          CREATE_ACCOUNT: 'Create Account',
          ADD_YOUR_USERNAME_AND_PASSWORD: 'Add your username and password.',
          ADD_PATIENT_USERNAME_AND_PASSWORD: 'Add patient\'s username and password.',
          GREAT_NEWS_OUR_SERVICE_IS_AVAILABLE_IN_YOUR_AREA: 'Great news! Our service is available in your area.',
          YOUR_CELLPHONE_IS_NOT_CONFIRMED: 'Your cellphone is not confirmed. Please enter the code you received ',
          HERE: 'here',
          ENTER_CONFIRMATION_CODE: 'Enter Confirmation Code',
          ENTER_THE_CODE_WE_SENT_TO: 'Enter the 6-digit code we sent to ',
          REQUEST_NEW_CODE: 'Request new code',
          MIN_8CHARS: '8 characters or more',
          MIN_1UPPERCASE: 'At least 1 uppercase letter',
          MIN_1NUMBER: 'At least 1 number',
          MIN_1SPECIAL: 'At least 1 special character',
        },
        DELIVERY_ADDRESS: {
          DELIVERY_ADDRESS: 'Delivery Address',
          ADD_YOUR_DELIVERY_ADDRESS: 'Add your delivery address.',
          ADD_PATIENT_DELIVERY_ADDRESS: 'Add patient\'s delivery address.',
          YOUR_DELIVERY_ADDRESSES: 'Your Delivery Addresses',
          PATIENT_DELIVERY_ADDRESSES: 'Patient Delivery Addresses',
          ADD_NEW_ADDRESS: 'Add a new address',
          NO_ADDRESS_ADDED: 'No address added!',
          ONLY_ONE_ADDRESS_CAN_BE_PREFERRED: 'Only one address can be preferred.',
          SORRY_WE_CANT_REMOVE_PREFERRED_ADDRESS: 'Sorry, we can\'t remove the preferred address.',
        },
        OTHER_INFO: {
          OTHER_INFORMATION: 'Other Information',
          ADD_YOUR_OTHER_INFORMATION: 'Add your other information (Insurance, ID, and Communication Preferences).',
          ADD_PATIENT_OTHER_INFORMATION: 'Add patient\'s other information (Insurance, ID, and Communication Preferences).',
          INSURANCE_INFORMATION: 'Insurance Information',
          ID_INFORMATION: 'ID Information',
          COMMUNICATION_PREFERENCES: 'Communication Preferences',
        },
        SUBSCRIPTION: {
          SUBSCRIPTION: 'Subscription',
          CHOOSE_YOUR_SUBSCRIPTION_PLAN_AND_PREFERRED_PAYMENT_METHOD: 'Choose your subscription plan and preferred payment method.',
          CHOOSE_PATIENT_SUBSCRIPTION_PLAN_AND_PREFERRED_PAYMENT_METHOD: 'Choose patient\'s subscription plan and preferred payment method.',
        },
        YOU_ARE_SET: {
          YOU_ARE_SET: 'You are Set!',
          WHATS_NEXT: 'What\'s Next?',
          ADD_HERE_FAMILY_MEMBERS_OR_ANYONE_UNDER_YOUR_CARE: 'Add here family members or anyone under your care.',
          YOU_CAN_ADD_OTHER_PEOPLE_OR_INVITE_SOMEONE: 'You can add other people you are caring for and manage their account or you can invite someone that is caring for you to register and manage your account for you.',
          I_WANT_TO_ADD_SOMEONE_IAM_CARING_FOR: 'I want to add someone I\'m caring for',
          I_WANT_TO_INVITE_SOMEONE_TO_CARE_FOR_ME: 'I want to invite someone to care for me',
        },
        CARETAKERS: {
          CARETAKERS: 'Caretakers',
          ADD_HERE_FAMILY_MEMBERS_OR_ANYONE_UNDER_YOUR_CARE: 'Add here family members or anyone under your care.',
          ADD_HERE_FAMILY_MEMBERS_OR_ANYONE_UNDER_YOUR_CARE_FULL: 'Add here family members or anyone under your care so you can subscribe them to the ME service and manage their account.',
          ADD_PEOPLE_UNDER_YOUR_CARE: 'Add people under your care',
          PEOPLE_UNDER_YOUR_CARE: 'People Under Your Care',
          NO_ONE_FOUND: 'No one found!',
          PENDING: 'Pending',
          APPROVED: 'Approved',
        },
        INVITE_CAREGIVER: {
          INVITE_CAREGIVER: 'Invite Caregiver',
          YOU_ARE_INVITING_SOMEONE_ELSE_AS_YOUR_CAREGIVER: 'You\'re inviting someone else, as your caregiver',
          ADD_YOUR_CAREGIVER_BASIC_INFORMATION: 'Add your caregiver basic information.',
          CAREGIVER_BASIC_INFORMATION: 'Caregiver Basic Information',
        },
        INVITATION_SENT: {
          INVITATION_SENT: 'Invitation Sent!',
          WHATS_NEXT: 'What\'s Next?',
          YOUR_INVITATION_REQUEST_HAS_BEEN_SENT_SUCCESSFULLY: 'Your invitation request has been sent successfully',
          THE_CAREGIVER_YOU_INVITED_WILL_RECEIVE_EMAIL_OR_TEXT: 'The caregiver you invited will receive an email or a text requesting them to join ME as your Caregiver. Once they accept your invitation you will receive a notification.',
        },
      },
    },
  },
};

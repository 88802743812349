// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const BASE_URL = 'http://dev-api.knostos.com';

export const environment = {
  production: false,
  API_BASE: {
    MOBILE: `${BASE_URL}/mobile-gateway/api/v1`,
  },
  HTTP_HEADER_KEY: {
    CONTENT_TYPE: 'Content-Type',
    APP_ID: 'app_id',
    AUTHORIZATION: 'Authorization',
  },
  LOCAL_STORAGE_KEY: {
    LANGUAGE: 'language',
    APP_ID: 'appId',
    TOKEN: 'token',
  },
  APP_CONFIG: {
    ME: {
      DOMAIN: 'dev-portal.knostos.com',
      ID: 'SUvAZ+iSYEqiOwapxw8kSg==',
      TITLE: 'ME Portal',
      FAVICON: 'assets/me/favicon.ico',
      THEME: 'me-styles.css',
      CALLBACK_URL: 'https://dev.mecares.com/cm-patient-auth/api/callback',
    },
    MCS: {
      DOMAIN: 'dev.mcscarepr.com',
      ID: 'hIVBgL0EugSmZ+zlw1+/kg==',
      TITLE: 'MCS Care Enrollment Portal',
      FAVICON: 'assets/mcs/favicon.ico',
      THEME: 'mcs-styles.css',
      TERMS_OF_SERVICES_EN: 'https://www.medpluspr.com/mcscaretermsandconditions',
      TERMS_OF_SERVICES_ES: 'https://www.medpluspr.com/mcscareterminosycondiciones',
      PRIVACY_POLICY_EN: 'https://www.medpluspr.com/mcscareprivacypolicy',
      PRIVACY_POLICY_ES: 'https://www.medpluspr.com/mcscarepoliticadeprivacidad',
    },
    SALUS: {
      DOMAIN: 'dev-salus.knostos.com',
      ID: 'Sj4V4FA6T7mpOD38O6nCcg==',
      TITLE: 'Salus Clinics Portal',
      FAVICON: 'assets/salus/favicon.ico',
      THEME: 'salus-styles.css',
      CALLBACK_URL: 'https://saluspr.com/',
    },
    PHARMPIX: {
      DOMAIN: 'dev-pharmpix.qwikenroll.com',
      ID: 'sJsV8HvuEOPZFc4oKcK3rg==',
      TITLE: 'Pharmpix Specialty Hub Enrollment Portal',
      FAVICON: 'assets/pharmpix/favicon.ico',
      THEME: 'pharmpix-styles.css',
      TERMS_OF_SERVICES_EN: 'terms/TermsOfServices.pdf',
      TERMS_OF_SERVICES_ES: 'terms/TerminosDeServicio.pdf',
      PRIVACY_POLICY_EN: 'terms/PrivacyPolicy.pdf',
      PRIVACY_POLICY_ES: 'terms/PoliticaDePrivacidad.pdf',
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

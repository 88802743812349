import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {IAddress, ICareInfo, IOtherInfo, IPreSigned, IUser} from '../models';

const MOBILE_BASE = environment.API_BASE.MOBILE;

@Injectable()
export class UserService {

  constructor(private http: HttpClient) {}

  checkExistence(key: string, value: string): Observable<{ is_available: boolean }> {
    const fromString = `${key}=${value}`;
    const params = new HttpParams({ fromString });

    return this.http.get<{ is_available: boolean }>(`${MOBILE_BASE}/user/availability`, { params });
  }

  signup(user: Partial<IUser>): Observable<IUser> {
    return this.http.post<IUser>(`${MOBILE_BASE}/user/portal/signup`, user);
  }

  getAddresses(userId = 0): Observable<IAddress[]> {
    return this.http.get<IAddress[]>(`${MOBILE_BASE}/user/${userId}/locations`);
  }

  addAddress(userId = 0, address: Partial<IAddress>): Observable<IAddress> {
    return this.http.post<IAddress>(`${MOBILE_BASE}/user/${userId}/locations`, address);
  }

  updateAddress(userId = 0, address: Partial<IAddress>): Observable<IAddress> {
    return this.http.put<IAddress>(`${MOBILE_BASE}/user/${userId}/locations/${address?.id}`, address);
  }

  deleteAddress(userId = 0, addressId: number): Observable<HttpResponse<void>> {
    return this.http.delete<void>(`${MOBILE_BASE}/user/${userId}/locations/${addressId}`, { observe: 'response' });
  }

  getPreSigned(userId = 0, name: string): Observable<IPreSigned> {
    return this.http.post<IPreSigned>(`${MOBILE_BASE}/user/${userId}/attachments`, { name });
  }

  updateOtherInfo(userId = 0, otherInfo: Partial<IOtherInfo>): Observable<IOtherInfo> {
    return this.http.put<IOtherInfo>(`${MOBILE_BASE}/user/${userId}/other_info`, otherInfo);
  }

  getRelationTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${MOBILE_BASE}/relation_types`);
  }

  inviteCaregiver(userId = 0, caregiver: Partial<IUser>): Observable<IUser> {
    return this.http.post<IUser>(`${MOBILE_BASE}/user/${userId}/invite`, caregiver);
  }

  getCaretakers(userId = 0): Observable<{ caregivers: ICareInfo[], caretakers: ICareInfo[] }> {
    return this.http.get<{ caregivers: ICareInfo[], caretakers: ICareInfo[] }>(`${MOBILE_BASE}/user/${userId}/relations`);
  }
}

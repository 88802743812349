import {Injectable} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {CoreState} from '../../store';
import {HttpContentType} from '../enums';

const HTTP_HEADER_KEY = environment.HTTP_HEADER_KEY;

@Injectable()
export class AuthService {

  constructor(private coreState: CoreState) {}

  getHeaders(): HttpHeaders {
    const coreStateValue = this.coreState.get().value;
    const httpHeaders = new HttpHeaders()
      .set(HTTP_HEADER_KEY.CONTENT_TYPE, HttpContentType.APP_JSON)
      .set(HTTP_HEADER_KEY.APP_ID, coreStateValue.appId);

    if(coreStateValue.token) httpHeaders.set(HTTP_HEADER_KEY.AUTHORIZATION, coreStateValue.token);

    return httpHeaders;
  }
}

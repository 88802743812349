import {LanguageSymbol} from '../../enums';
// Spain
export const locale = {
  lang: LanguageSymbol.SPANISH,
  data: {
    APP: {
      NAME: 'PORTAL',
      GENERAL: {
        LANGUAGE: 'Lenguaje',
        AND: 'y',
        EDIT: 'Editar',
        REMOVE: 'Remover',
        BACK: 'Atrás',
        OK_GOT_IT: '¡Ok, entendido!',
        CONTINUE: 'Continuar',
        IGNORE: 'Ignorar',
        SUBMIT: 'Enviar',
        FINISH: 'Terminar',
        CLOSE: 'Cerca',
        ADD: 'Añadir',
        UPDATE: 'Actualizar',
        COMPLETE_SUBSCRIPTION: 'Suscripción completa',
      },
      SIGNUP: {
        FIELD: {
          ZIPCODE: 'Código Postal',
          MEMBERSHIP_ID: 'Identificación de membresía',
          REFERRAL_CODE: 'Código de referido',
          GIVEN_NAME: 'Nombre',
          SECOND_NAME: 'Segundo nombre',
          FAMILY_NAME: 'Apellido paterno',
          MOTHERS_MAIDEN_FAMILY_NAME: 'Apellido materno',
          DATE_OF_BIRTH: 'Fecha de nacimiento',
          GENDER: 'Género',
          CELL_NUMBER: 'Teléfono móvil',
          HOME_NUMBER: 'Teléfono del hogar',
          FAX_NUMBER: 'Teléfono de fax',
          EMAIL: 'Correo electrónico',
          USERNAME: 'Nuevo nombre de usuario',
          PASSWORD: 'Nueva contraseña',
          RE_PASSWORD: 'Vuelva a introducir la contraseña',
          DELIVERY_ZIPCODE: 'Código postal de lugar de entrega',
          HOME: 'Casa',
          OFFICE: 'Oficina',
          OTHER: 'Otro',
          URBANIZATION: 'Urbanización',
          ADDRESS_LINE1: 'Dirección línea 1',
          ADDRESS_LINE2: 'Dirección línea 2',
          CITY: 'Ciudad',
          STATE: 'Estado',
          ZIPCODE_4CODE: 'Código Postal + 4Code',
          DELIVERY_INSTRUCTIONS: 'Instrucciones de entrega',
          PRESS123_AT_FRONT_GATE: 'Presione \'123\' en la puerta principal.',
          PREFERRED_ADDRESS: 'Dirección preferida',
          TYPE_OF_ADDRESS: 'Tipo de dirección',
          PRIMARY_INSURED_RELATION: 'Relación con el asegurado principal',
          PRIMARY_INSURED_NAME: 'Nombre del asegurado principal',
          CONTRACT_NUMBER: 'Número de contrato',
          RX_GROUP: 'Grupo Rx',
          FRONT_IMAGE_INSURANCE_CARD: 'Imagen frontal de la tarjeta de seguro de salud',
          BACK_IMAGE_INSURANCE_CARD: 'Imagen lateral de la tarjeta de seguro de salud',
          GOVERNMENT_ID_NUMBER: 'Número de la ID gubernamental',
          GOVERNMENT_ID_STATE: 'Estado de la ID gubernamental',
          IMAGE_GOVERNMENT_ID_CARD: 'Imagen de la tarjeta de ID gubernamental',
          PATIENT_SMS: 'SMS del paciente (Mensaje de texto)',
          PATIENT_EMAIL: 'Correo electrónico del paciente',
          PATIENT_PHONE_CALL: 'Llamada telefónica del paciente',
          PATIENT_MAIL: 'Patient Mail',
          CATEGORY: 'Categoría',
          IMAGE: 'Imagen',
          SKU: 'SKU',
          NAME: 'Nombre',
          COUNT: 'Contar',
          PRICE: 'Precio',
          VALIDATION: {
            REQUIRED: '{{label}} debe ser completada',
            INVALID: '{{label}} no válida',
            NO_WHITESPACE: '{{label}} no válida: No se permiten espacios en blanco',
            MIN: '{{label}} debe ser mayor que {{min}}',
            EXISTS: '{{label}} ya está en uso',
            PATTERN: '{{label}} debe coincidir con el patrón',
            PW_MATCH: 'Las contraseñas no coinciden',
          },
        },
        PROGRAM_TYPE: {
          SALUS_PROGRAMS: 'Programas Salus',
          SELECT_PROGRAM_YOU_WANT_TO_REGISTER_FOR: 'Seleccione el programa en el cual desea registrarse.',
          REGISTRATION_PROCESS_MAY_DIFFER_BASED_ON_YOUR_SELECTION: 'El proceso de registro pudiera diferir según su selección.',
          LIVING_HEALTH_PROGRAM: 'Living Health Program',
          REGISTER_FOR_THE_SALUS_LIVING_HEALTH_PROGRAM: 'Regístrese en el Salus Living Health Program',
          WELLNESS_PROGRAM: 'Wellness Program',
          REGISTER_FOR_THE_SALUS_WELLNESS_PROGRAM: 'Regístrese en el Salus Wellness Program',
          MEDICATION_MANAGEMENT_PROGRAM: 'Medication Management Program',
          REGISTER_FOR_THE_SALUS_MEDICATION_MANAGEMENT_PROGRAM: 'Regístrese en el Salus Medication Management Program',
          BY_MAKING_A_SELECTION_YOU_AGREE_WITH_OUR: 'Al hacer una selección, usted está de acuerdo con nuestro',
          TERMS_OF_SERVICES: 'Términos de Servicios',
          PRIVACY_POLICY: 'Política de Privacidad',
        },
        CHECK_ELIGIBILITY: {
          ELIGIBILITY_CHECK: 'Verificación de elegibilidad',
          CHECK_IF_THE_PATIENT_IS_ELIGIBLE_FOR_THIS_PROGRAM: 'Verifique si el paciente es elegible para este programa.',
          INVALID_DATA_PROVIDED: 'Los datos proporcionados no coinciden con nuestros sistemas. Verifique su identificación de membresía y fecha de nacimiento, luego vuelva a intentarlo.',
          ALREADY_ENROLLED: 'El paciente con esta identificación de membresía ya se inscribió. Póngase en contacto con el servicio de atención al cliente para cualquier modificación.',
        },
        BASIC_INFO: {
          BASIC_INFORMATION: 'Información básica',
          ADD_YOUR_BASIC_INFORMATION: 'Agrega tu información básica.',
          ALREADY_EXIST_PART_1: 'Un contacto con este número telefonico existe. Por favor intente ',
          ALREADY_EXIST_PART_2: ' con su Medplus User Id o de entender que esto es un error, por favor comuniquese con nuestro departamento de servicio al cliente.',
          LOGIN: 'ingresar',
        },
        CREATE_ACCOUNT: {
          CREATE_ACCOUNT: 'Crear cuenta',
          ADD_YOUR_USERNAME_AND_PASSWORD: 'Agregue su usuario y contraseña.',
          GREAT_NEWS: '¡Una gran noticia! Usted es elegible para este programa, agregue su nombre de usuario y contraseña.',
          MIN_8CHARS: '8 caracteres o más',
          MIN_1UPPERCASE: 'Al menos una (1) letra mayúscula',
          MIN_1NUMBER: 'Al menos un (1) número',
          MIN_1SPECIAL: 'Al menos un (1) carácter especial',
        },
        DELIVERY_ADDRESS: {
          DELIVERY_ADDRESS: 'Dirección de entrega',
          ADD_YOUR_DELIVERY_ADDRESS: 'Añade tus direcciones de entrega.',
          YOUR_DELIVERY_ADDRESSES: 'Sus direcciones de entrega',
          ADD_NEW_ADDRESS: 'Añadir una nueva dirección',
          NO_ADDRESS_ADDED: '¡No se añadió ninguna dirección!',
          ONLY_ONE_ADDRESS_CAN_BE_PREFERRED: 'Solo se puede tener una dirección como preferida.',
          SORRY_WE_CANT_REMOVE_PREFERRED_ADDRESS: 'Lo sentimos, no podemos eliminar la dirección preferida.',
        },
        OTHER_INFO: {
          OTHER_INFORMATION: 'Otra información',
          ADD_YOUR_OTHER_INFORMATION: 'Agregue su otra información (seguro, identificación y preferencias de comunicación).',
          INSURANCE_INFORMATION: 'Información del seguro',
          ID_INFORMATION: 'Información de identificación',
          COMMUNICATION_PREFERENCES: 'Preferencia de comunicacion',
        },
        ORDER: {
          ORDER_DEVICES: 'Dispositivos de pedido',
          SELECT_DEVICES_TO_BE_ORDERED: 'Seleccione los dispositivos a ordenar.',
          SELECTED_DEVICES_TOTALS: 'Totales de dispositivos seleccionados',
          SELECTED_DEVICES_LIST: 'Lista de dispositivos seleccionados',
          NO_DEVICES_SELECTED: '¡No se seleccionó ningún dispositivo!',
          TOTAL_COUNT: 'Cuenta total',
          TOTAL_COST: 'Coste total',
          VIEW_SELECTED_DEVICES_LIST: 'Ver la lista de dispositivos seleccionados',
        },
        DONE: {
          YOU_ARE_SET: '¡Exito. Su registro ha sido completado!',
          WHATS_NEXT: '¿Qué es lo próximo?',
          CONGRATULATIONS_YOU_HAVE_BEEN_SUCCESSFULLY_REGISTERED: '¡Felicidades! Te has registrado exitosamente.',
          THANK_YOU_FOR_YOUR_TIME: 'Gracias por su tiempo. Siéntase libre de navegar a otra página en cualquier momento, o puede iniciar sesión desde ',
          HERE: 'aquí',
        },
      },
    },
  },
};

import {Injectable} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Location} from '@angular/common';
import {filter} from 'rxjs';

@Injectable()
export class NavigationService {
  private readonly _history: string[] = [];

  constructor(
    private router: Router,
    private location: Location,
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      this._history.push((event as NavigationEnd).urlAfterRedirects);
    });
  }

  back(): void {
    if(this._history.length) {
      this._history.pop();

      return this.location.back();
    }

    this.router.navigateByUrl('/');
  }

  path(): string {
    return this._history[this._history.length - 1];
  }

  previousPath(): string {
    const historyLength = this._history.length;

    if(historyLength > 1) return this._history[historyLength - 2];

    return '';
  }
}

import {LanguageSymbol} from '../../enums';
// USA
export const locale = {
  lang: LanguageSymbol.ENGLISH,
  data: {
    APP: {
      STEPPER_TITLE: 'Enrollment Process',
      GENERAL: {
        LANGUAGE: 'Language',
        ENGLISH: 'English',
        SPANISH: 'Español',
        AND: 'and',
        INFORMATION_WITH_ASTERISK: 'Information marked with an asterisk (*) is required.',
        BACK: 'Back',
        CONTINUE: 'Continue',
        ADD: 'Add',
        UPDATE: 'Update',
        EDIT: 'Edit',
        REMOVE: 'Remove',
        CLOSE: 'Close',
        OK_GOT_IT: 'OK, Got it!',
      },
      SIGNUP: {
        FIELD: {
          NO_CARDHOLDER_NUMBER: 'I do not have the information available; I will proceed to continue completing the registration process.',
          CARDHOLDER_NUMBER: 'Cardholder Number',
          DATE_OF_BIRTH: 'Date of Birth',
          FIRST_NAME: 'First Name',
          SECOND_NAME: 'Second Name',
          LAST_NAME: 'Last Name',
          MOTHER_NAME: 'Mother’s Maiden Name',
          GENDER: 'Gender',
          MALE: 'Male',
          FEMALE: 'Female',
          AGE: 'Age',
          CELL_NUMBER: 'Cell Number',
          TEXTS_TO_PHONE: 'Texts to your phone?',
          HOME_NUMBER: 'Home Number',
          FAX_NUMBER: 'Fax Number',
          DELIVERY_DATE: 'Expected Delivery Date',
          EMAIL: 'Email',
          PREFERRED_METHOD: 'Preferred Method',
          PHONE_CALL: 'Phone Call',
          TEXT_MESSAGE: 'Text Message',
          GROUP_ID: 'Group ID:',
          GROUP_NAME: 'Group Name:',
          HEIGHT: 'Height:',
          FT: 'ft',
          IN: 'in',
          WEIGHT: 'Weight:',
          LB: 'Pounds',
          ANY_ALLERGIES: 'Check here if you have any allergies.',
          ALLERGIES: 'Allergies',
          ALLERGIES_PLACEHOLDER: 'Please enter the details of the allergies.',
          NO_CONTACT: 'Please uncheck the checkbox if you want to add additional contact information.',
          RELATIONSHIP: 'Relationship',
          AUNT: 'Aunt',
          COUSIN: 'Cousin',
          DAUGHTER: 'Daughter',
          FATHER: 'Father',
          HUSBAND: 'Husband',
          MOTHER: 'Mother',
          OTHER: 'Other',
          SON: 'Son',
          UNCLE: 'Uncle',
          WIFE: 'Wife',
          EMERGENCY: 'Emergency',
          PHI_AUTHORIZED: 'PHI Authorized',
          NO_INSURANCE: 'I don’t have any other insurance and/or assistance.',
          INSURANCE_TYPE: 'Select the payer type',
          PATIENT_ASSISTANCE: 'Financial Aid Program',
          OTHER_INSURANCE: 'Other Insurance',
          INSURANCE_NAME: 'Insurance Name',
          SELECT_INSURANCE: 'Select the name of the health insurance',
          COMMERCIAL: 'Commercial',
          PLAN_VITAL: 'Plan Vital (Reforma)',
          INSURANCE_ID: 'Medical Plan ID and/or Patient Assistance ID',
          HEALTH_PLAN_PHOTO: 'Photo of the health plan and/or assistance program',
          DELIVER_TO_DOCTOR: 'Deliver medications to the doctor’s office.',
          ADDRESS_TYPE: 'Type of Address',
          HOME: 'Home',
          WORK: 'Work',
          URBANIZATION: 'Urbanization',
          ADDRESS_1: 'Address line 1',
          ADDRESS_2: 'Address line 2',
          CITY: 'City',
          STATE: 'State',
          ZIP_CODE: 'Zip Code + 4 Code',
          EXPECTED_DELIVERY_DATE: 'Expected Delivery Date',
          DELIVERY_INSTRUCTIONS: 'Delivery Instructions',
          DELIVERY_INSTRUCTIONS_PLACEHOLDER: 'Please add your delivery instructions.',
          PREFERRED_ADDRESS: 'Preferred Address',
          PREFERRED_ADDRESS_INFO: 'Only one address can be preferred.',
          VALIDATION: {
            REQUIRED: '{{label}} is required',
            INVALID: 'Invalid {{label}}',
            MIN: '{{label}} must be greater than {{min}}',
            MIN_EQUAL: '{{label}} must be greater than or equal to {{min}}',
          },
        },
        START_PROCESS: {
          TITLE: 'Start',
          WELCOME: 'WELCOME to PharmPix Specialty HUB®',
          INTRODUCTION: 'PharmPix Specialty HUB® (PSH) manages the dispensing of your specialized medications through a unique service center (HUB). This center (HUB) will provide the necessary support to help you access available financial assistance to cover your payment responsibility for specialized medications. It will streamline the approval process with your medical plan and coordinate the delivery of your medications to your home, workplace, or medical office. The PharmPix Specialty HUB® program utilizes a network of specialized pharmacies in partnership with providers in Puerto Rico and the United States.',
          CLICK_TO_START: 'Click here to start your process!',
          BY_STARTING_PROCESS: 'By starting the process, you agree with our',
          TERMS_OF_SERVICES: 'Terms of Services',
          PRIVACY_POLICY: 'Privacy Policy',
        },
        CHECK_ELIGIBILITY: {
          TITLE: 'Eligibility Check',
          CHECK_IF_ELIGIBLE: 'Check if the patient is eligible for this Program.',
        },
        PATIENT_INFO: {
          TITLE: 'Patient Information',
          ADD_PATIENT: 'Add your patient information.',
        },
        CONTACT_INFO: {
          TITLE: 'Contact Information',
          LONG_TITLE: 'Authorized Contact Information',
          ADD_CONTACT: 'Add your authorized contact information.',
        },
        INSURANCE: {
          TITLE: 'Additional Insurance',
          LONG_TITLE: 'Other Insurance & Patient Assistance Information',
          ADD_INSURANCE: 'Add new Insurance or Patient Assistance Program',
          LONG_ADD_INSURANCE: 'Add the details of any additional insurance (not MCS) or Patient Assistance Programs you are enrolled in.',
          AT_LEAST_ONE: 'At least one Insurance or Assistance Program should be added.',
        },
        DELIVERY_ADDRESS: {
          TITLE: 'Delivery Address',
          ADD_ADDRESS: 'Add your delivery address.',
          ADD_NEW_ADDRESS: 'Add new delivery address',
          AT_LEAST_ONE: 'At least one address should be added.',
        },
        END_PROCESS: {
          TITLE: 'Enrollment Done',
          THANK_YOU: 'Thank you for completing your enrollment process!',
          EMAIL_WITH_DETAILS: 'An email containing your registration details will be sent to you',
          ALREADY_ENROLLED: 'Dear affiliate, our records show that you have completed your registration process',
        },
      },
    },
  },
};

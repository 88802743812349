import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {environment} from '../environments/environment';

const APP_CONFIG = environment.APP_CONFIG;
const getRoutes = (): Routes => {
  const hostname = window.location.hostname;

  switch(true) {
    case new RegExp(APP_CONFIG.ME.DOMAIN).test(hostname):
      return [
        {
          path: 'signup',
          loadChildren: () => import('./modules/me/signup/signup.module').then(me => me.SignupModule),
        },
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'signup',
        },
      ];

    case new RegExp(APP_CONFIG.MCS.DOMAIN).test(hostname):
      return [
        {
          path: 'signup',
          loadChildren: () => import('./modules/mcs/signup/signup.module').then(mcs => mcs.SignupModule),
        },
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'signup',
        },
      ];

    case new RegExp(APP_CONFIG.SALUS.DOMAIN).test(hostname):
      return [
        {
          path: 'signup',
          loadChildren: () => import('./modules/salus/signup/signup.module').then(salus => salus.SignupModule),
        },
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'signup',
        },
      ];

    case new RegExp(APP_CONFIG.PHARMPIX.DOMAIN).test(hostname):
      return [
        {
          path: 'enroll',
          loadChildren: () => import('./modules/pharmpix/enroll/enroll.module').then(pharmpix => pharmpix.EnrollModule),
        },
        {
          path: '',
          pathMatch: 'full',
          redirectTo: 'enroll',
        },
      ];

    default:
      return [];
  }
}

@NgModule({
  imports: [RouterModule.forRoot(getRoutes(), { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
